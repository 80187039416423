import React, { useState } from 'react';
import EditForm from './EditForm';
import { mutate } from 'swr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import UIModal from '../../v2/shared/ui/Modal';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const Info = ({
  dependent: {
    id,
    first_name,
    last_name,
    email,
    relationship,
    phone,
    last_activity_at,
    status,
    consumed_sessions
  },
  setEditing,
}) => {
  const [sendingEmail, setSendingEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sendingEmailStatus, setSendingEmailStatus] =
    useState('Sending Email...');

  async function deleteDependent() {
    if (
      !confirm(
        `Are you sure you want to remove ${first_name} ${last_name} from your dependents?`,
      )
    ) return;

    const res = await fetch(`/api/v2/user_dependents/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      mutate('/api/v2/user_dependents', [], true);
    } else {
      const { errors } = await res.json();
      setErrors(errors[0]);
    }
  }

  async function sendResetPasswordEmail(id) {
    setSendingEmail(true)
     const res = await fetch(`/api/v2/user_dependents/${id}/reset_password`, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
       },
     });
    if (res.status === 200) {
      mutate('/api/v2/user_dependents', [], true);
      setSendingEmailStatus('Email Sent');
    } else {
      const { errors } = await res.json();
      setErrors(errors[0]);
      setSendingEmailStatus("Something went wrong")
    }
    setTimeout(() => {
      setSendingEmail(false);
    }, 3000);
  }
  if (status == 'Deleted'){
    return <></>
  }
  return (
    <tr style={{ fontSize: '0.85rem' }}>
      <td>{first_name}</td>
      <td>{last_name}</td>
      <td>{email}</td>
      <td>{relationship}</td>
      <td>{phone}</td>
      <td>
        {status == 'Pending' && !sendingEmail && (
          <button
            type="button"
            onClick={() => sendResetPasswordEmail(id)}
            style={{ height: 'fit-content', fontSize: '10px' }}
            className="mt-auto btn btn-general bg-white text-dark border rounded mr-2">
            Resend Email
          </button>
        )}
        {!last_activity_at && sendingEmail && (
          <p style={{ fontSize: '10px' }}>{sendingEmailStatus}</p>
        )}
        {status != 'Pending' && status}
      </td>
      <td>{consumed_sessions}</td>
      <td>
        {status == 'Pending' && (
          <span onClick={() => setEditing(true)} className="btn btn-sm pt-0">
            <i className="far fa-edit"></i>
          </span>
        )}
      </td>
      <td>
        <StyledFontAwesomeIcon
          icon={faTrashAlt}
          onClick={() => setShowModal(true)}
        />
        <UIModal
          header={'Delete Dependent?'}
          description={`Are you sure you want to remove ${first_name} ${last_name} from your dependents?`}
          show={showModal}
          setShowModal={setShowModal}
          successAction={deleteDependent}
        />
      </td>
    </tr>
  );
};

const Display = ({ user }) => {
  const [editing, setEditing] = useState(false);
  const [dependent, setDependent] = useState(user);
  return editing ? (
    <tr style={{ fontSize: '0.85rem' }}>
      <td colSpan="6">
        <EditForm
          dependent={dependent}
          setEditing={setEditing}
          setDependent={setDependent}
        />
      </td>
    </tr>
  ) : (
    <Info dependent={dependent} setEditing={setEditing} />
  );
};

export default Display;
